var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.label
    ? _c(
        "router-link",
        {
          attrs: {
            to: { name: "label-detail", params: { id: _vm.label.LabelID } }
          }
        },
        [
          _vm.prefix ? [_vm._v(_vm._s(_vm.prefix) + " ")] : _vm._e(),
          _c("strong", [_vm._v(_vm._s(_vm.label.Name))])
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }