<template>
  <router-link :to="{ name: 'label-detail', params: { id: label.LabelID } }" v-if="label">
    <template v-if="prefix">{{ prefix }} </template>
    <strong>{{ label.Name }}</strong>
  </router-link>
</template>

<script>
  export default {
    name: 'LabelLink',
    components: {
    },
    props: {
      prefix: String,
      label: [Boolean, Object],
    },
  }
</script>